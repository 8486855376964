import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import messages from './i18n'

import VueMq from 'vue-mq'

import VueScrollTo from 'vue-scrollto'

import Vuelidate from 'vuelidate'

import VueParallaxJs from 'vue-parallax-js'

import VueNumber from 'vue-number-animation'

import VueObserveVisibility from 'vue-observe-visibility'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false

// var egg = "  ___ _    ___ __  __ ___ _  _ _____    ___   _   _  _ _____   _____ _____ \n" +
// " / __| |  | __|  \\/  | __| \\| |_   _|  / __| /_\\ | \\| |_ _\\ \\ / / __|_   _|\n" +
// "| (__| |__| _|| |\\/| | _|| .` | | |   | (_ |/ _ \\| .` || | \\ V /| _|  | |  \n" +
// " \\___|____|___|_|  |_|___|_|\\_| |_|    \\___/_/ \\_\\_|\\_|___| \\_/ |___| |_|  \n" +
// "                                                                          \n";

// console.log(egg)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 576,
    tablet: 1025,
    desktop: Infinity,
  }
})

Vue.use(VueScrollTo)

Vue.use(VueParallaxJs)

Vue.use(Vuelidate)

Vue.use(VueNumber)

Vue.use(VueObserveVisibility)

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 1,
  newestOnTop: true
});

Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: 'fr',
    messages,
})

new Vue({
  i18n,
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
