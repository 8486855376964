<template>
    <header class="header fixed-top w-100" :class="{'bg-color-fill': scrollPosition > 50}">

        <nav class="navbar w-100" role="navigation" v-if="$mq === 'desktop'">
            <div class="navbar-container">
                <div class="navbar-left">
                    <div class="nav-item">
                        <router-link :to="getRoute('About')">
                            {{ $t('nav.about') }}
                        </router-link>
                        <div class="hover-container"></div>
                    </div>
                    <div class="nav-item">
                        <router-link :to="getRoute('Projects')">
                            {{ $t('nav.projects') }}
                        </router-link>
                        <div class="hover-container"></div>
                    </div>
                </div>

                <div class="navbar-center">
                    <div class="nav-item">
                        <router-link :to="getRoute('Home')">
                            <img src="@/assets/img/logos/olinawhite.png" alt="Olina Lab logo" />
                        </router-link>
                    </div>
                </div>

                <div class="navbar-right">
                    <div class="nav-item">
                        <router-link :to="getRoute('Contact')">
                            Contact
                        </router-link>
                        <div class="hover-container"></div>
                    </div>
                    <div class="nav-item cta">
                        <router-link :to="getRoute('Start')">
                            {{ $t('nav.start') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>

        <nav class="nav-mobile-icons" :class="$mq" v-else>
            <div class="mx-4">
                <span class="icon mdi mdi-menu" @click="openMenu"></span>
            </div>
            <div>
                <router-link :to="getRoute('Home')">
                    <img src="@/assets/img/logos/olinawhite.png" alt="Olina Lab logo" />
                </router-link>
            </div>
            <!-- <div class="mr-2">
                <router-link :to="oppositeRouteName">
                    {{ oppositeLocale.toUpperCase() }}
                </router-link>
            </div> -->
        </nav>
        <transition name="fade" mode="out-in">
        <div id="navigationMobileContent" v-if="isMenuOpen">
            <div class="close-btn">
                <span class="icon mdi mdi-close" @click="openMenu"></span>
            </div>
            <div v-for="item in menuMobile" :key="item.label" class="navigationMobileContent_element" :class="{ 'tablet': $mq === 'tablet', 'start': item.cta }">
                <span @click="goTo(item.to)">{{item.label}}</span>
            </div>
            <div id="navigationMobileSocialLinks" :class="$mq">
                <div class="icon">
                    <a :href="fbLink">
                        <span class="icon mdi mdi-facebook"></span>
                    </a>
                </div>
                <div class="icon">
                    <a :href="instaLink">
                        <span class="icon mdi mdi-instagram"></span>
                    </a>
                </div>
                <div :class="ldLink">
                    <a href="#">
                        <span class="icon mdi mdi-linkedin"></span>
                    </a>
                </div>
            </div>
        </div>
        </transition>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "Navigation",
    data() {
        return {
            isMenuOpen : false,
            scrollPosition: null,
            instaLink: process.env.VUE_APP_INSTAGRAM_URL,
            fbLink: process.env.VUE_APP_FACEBOOK_URL,
            ldLink: process.env.VUE_APP_LINKEDIN_URL
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
        openMenu(){
            this.isMenuOpen = !this.isMenuOpen

            // prevent scrolling if menu is open
            this.isMenuOpen ? document.documentElement.style.overflow = 'hidden': document.documentElement.style.overflow = 'auto'
        },
        goTo(to){
            this.$router.push(to);
            this.isMenuOpen = false;
            document.documentElement.style.overflow = 'auto';
        },
        updateScroll() {
            this.scrollPosition = window.scrollY
        }
    },
    computed: {
        ...mapGetters({
            getRoute: 'ui/getRoute',
            locale: 'ui/locale',
            localeLoaded: 'ui/localeLoaded',
            oppositeLocale: 'ui/oppositeLocale'
        }),

        oppositeRouteName() {
            let name = null
            if (this.$route.name) {
                name = this.$route.name.split('-')[0] + '-' + this.oppositeLocale
            }
            return name ? { name } : null
        },

        menu() {
            return [
                    {
                        label: this.$t('nav.home'),
                        to: this.getRoute('Home'),
                        hoverable: true
                    },
                    {
                        label: this.$t('nav.about'),
                        to: this.getRoute('About'),
                        hoverable: true
                    },
                    {
                        label: this.$t('nav.projects'),
                        to: this.getRoute('Projects'),
                        hoverable: true
                    },
                    {
                        label: 'Contact',
                        to: this.getRoute('Contact'),
                        hoverable: true
                    },
                    {
                        label: this.$t('nav.start'),
                        to: this.getRoute('Start'),
                        hoverable: false,
                        cta: true
                    },
                    {
                        label: this.oppositeLocale.toUpperCase(),
                        to: this.oppositeRouteName,
                        hoverable: false
                    }
                ]
        },

        menuMobile() {
            return [
                    {
                        label: this.$t('nav.about'),
                        to: this.getRoute('About')
                    },
                    {
                        label: this.$t('nav.projects'),
                        to: this.getRoute('Projects')
                    },
                    {
                        label: 'Contact',
                        to: this.getRoute('Contact')
                    },
                    {
                        label: this.$t('nav.start'),
                        to: this.getRoute('Start'),
                        cta: true
                    }
                ]
        },
    }
}
</script>

<style lang="scss" scoped>
.header {
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    text-align: center;
    padding: 20px 0;

    &.bg-color-fill {
        padding: 0;
        background-color: $primary;
        -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        transition: all 0.3s linear;

        .hover-container {
            background-color: $white !important;
        }

        .cta {
            a:hover {
                color: $primary !important;
            }
        }
    }

    nav {
        background: none !important;
        font-size: 18px;
        padding: 0;

        .navbar-container {
            max-width: 1140px;
            margin: auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .navbar-left, .navbar-right {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .nav-item {
            padding: 15px 0;
            position: relative;
            cursor: pointer;

            img {
                max-width: 40%;
            }

            .hover-container {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 3px;
                background-color: $primary;
                transition: 450ms ease-in-out;
            }

            &:hover {
                .hover-container {
                    width: 100px;
                }
            }

            &.cta {
                a {
                    border: 1px solid $white;
                    border-radius: 35px;
                    text-transform: uppercase;
                    min-width: 230px;
                    padding: 5px 30px;
                    background: transparent;
                    /* Old browsers */
                    background: linear-gradient(to left, transparent 50%, $white 50%);
                    background-size: 200% 100%;
                    background-position: right bottom;
                    transition: 450ms ease-in-out;

                    &:hover {
                        color: $black;
                        background-position: left bottom;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $mobile) {
        .navbar {
            text-align: center !important;
            float: none;
            display: inline-block;
        }
    }

    .nav-mobile-icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 10px 0 10px;

        &.tablet {
            margin-left: 10px;
            div {
                &:first-child {
                    font-size: 48px;
                }
            }
        }

        div {
            align-self: center;

            &:first-child {
                justify-self: start;
                font-size: 30px;
            }

            &:last-child {
                justify-self: end;
            }
        }

        img {
            max-width: 100%;
        }
    }

    #navigationMobileContent {
        height: 100vh;
        background-color: $white;
        display: flex;
        position: absolute;
        width: 100%;
        flex-direction: column;
        align-items: center;
        z-index: 200;
        top: 0;
        padding-top: 30px;

        .navigationMobileContent_element {
            width: 90%;
            border-bottom: 1px solid #cad3e6;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;

            &:first-child {
                border-top: 1px solid #cad3e6;
                margin-top: 30px;
                @media screen and (max-width: 320px) {
                    margin-top: 10px;
                }
            }

            &.tablet {
                width: 80%;
            }

            &.start {
                border: none;

                span {
                    background-color: $primary;
                    display: inline-block;
                    width: 230px;
                    border: 1px solid $primary;
                    border-radius: 35px;
                    padding: 14px 38px;
                    font-weight: bold;
                    color: $white;
                }
            }
        }

        .close-btn {
            color: $black;
            font-size: 40px !important;
            position: absolute;
            right: 15px;
            top: 0;
        }

        span {
            text-decoration: none;
            text-transform: uppercase;
            color: $black;
            letter-spacing: 2.28px;
        }

        #navigationMobileSocialLinks {
            display: flex;
            flex-direction: row;
            color: $black;
            font-size: 1.5rem;
            @media screen and (max-width: 320px) {
                margin-top: 20px;
            }
            margin-top: 40px;

            .icon {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>