export default {
    fr: {
        footer: {
            'social-networks': 'Rejoignez-nous sur nos réseaux sociaux !',
            desc: 'Savant mélange de créativité et de technologies modernes pour vous aider à grandir sur le web.',
            projects: 'Projets',
            links: {
                title: 'Liens utiles',
                start: 'Démarrer un projet',
                mentions: 'Mentions légales',
                data: 'Données personnelles'
            },
            copyright: 'Tous droits réservés.'
        }
    },
    en: {
        footer: {
            'social-networks': 'Get connected with us on social networks!',
            desc: 'A clever mix of creativity and modern technology to help you grow on the web.',
            projects: 'Projects',
            links: {
                title: 'Useful links',
                start: 'Start a project',
                mentions: 'Terms and Conditions',
                data: 'Personnal data'
            },
            copyright: 'All rights reserved.'
        }
    }
}