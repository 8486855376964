export default {
    fr: {
        home: {
            title: 'Création de sites web à Caen en Normandie',
            subtitle: 'Spécialiste dans la création sur le web.',
            start: 'Démarrer un projet',
            services: {
                title: 'Nos services',
                website: {
                    title: 'Sites vitrine',
                    desc: "L'équipe d'experts Olina Lab peut concevoir un site web de toute complexité, d'une landing page à un site d'entreprise de plusieurs pages. Le tout en répondant à tous vos besoins."
                },
                websol: {
                    title: 'Applications web',
                    desc: "L'équipe Olina Lab répondra à tous vos besoins de logiciels spécifiques. Une application web développera l'efficacité et la flexibilité de votre activité et collera à toutes vos demandes."
                },
                consult: {
                    title: 'Consultant',
                    desc: "L'équipe Olina Lab est heureuse de vous fournir des services de conseil en informatique professionnels. Nous aidons déjà de nombreuses entreprises depuis notre création."
                },
                ecomm: {
                    title: 'E-commerce',
                    desc: "Dans le monde moderne du web, les sites de e-commerce jouent un rôle essentiel dans l’activité de votre entreprise. L'équipe Olina Lab se fera un plaisir de vous aider."
                }
            },
            ctabanner: {
                title: 'Envie de démarrer un projet avec nous ?',
                subtitle: "Chez Olina Lab, nous travaillons tous les jours pour délivrer à nos clients les meilleurs solutions pour tous les objectis. Nous travaillons aussi bien avec des petits et des gros clients."
            },
            advantages: {
                title: 'Nos valeurs',
                innov: {
                    title: 'Innovation',
                    text: "Curieuse, notre équipe de passionnés s’assurera de vous proposer des solutions performantes et dans l’air du temps, quelque soit votre projet."
                },
                creat: {
                    title: 'Créativité',
                    text: 'Concevoir des expériences web de qualité, mettre l’utilisateur au coeur de nos réalisations et contribuer à la croissance de nos clients, c’est notre raison d’être.'
                },
                indiv: {
                    title: 'Unique',
                    text: "Nous utilisons une approche individuelle pour chacun de nos clients et Olina Lab n'offre jamais de décisions standard pour votre business."
                },
                react: {
                    title: 'Réactivité',
                    text: "Olina Lab répondra toujours avec rapidité et efficacité à toutes vos demandes, car chaque client est important pour nous."
                }
            }
        },
    },
    en: {
        home: {
            title: 'Websites creation in Caen, Normandy',
            subtitle: 'Olina Lab is a specialist in creation on the web. Websites to increase your internet presence, e-commerce or even tailor-made web solutions, our creative team will meet all your needs.',
            start: 'Start a project',
            services: {
                title: 'Our services',
                website: {
                    title: 'Websites',
                    desc: "Olina Lab expert team can design a website of any complexity, from a landing page to a corporate multipage website. Everything depends on what you are looking for."
                },
                websol: {
                    title: 'Web applications',
                    desc: 'Olina Lab team will meet all your specific software needs. A web application will develop the efficiency and flexibility of your activity and will meet all your requests.'
                },
                consult: {
                    title: 'IT consulting',
                    desc: 'Olina Lab team is glad to provide you with professional IT consulting services 24/7. We have been helping lots of companies since our establishment.'
                },
                ecomm: {
                    title: 'E-commerce',
                    desc: 'In the modern world of rapid web development, e-commerce websites play a vital role in your company’s activity. Olina Lab team is happy to assist you with it.'
                }
            },
            ctabanner: {
                title: 'Want to start a project with us?',
                subtitle: 'At Olina Lab, we work for our customers every day to deliver the best applications for any purposes. We work both with individual and corporate customers.'
            },
            advantages: {
                title: 'Our values',
                innov: {
                    title: 'Innovation',
                    text: 'Our inquisitive, future-oriented and passionate team will ensure that it suggests the most high-performance and up-to-date solutions for each and every project of yours.'
                },
                creat: {
                    title: 'Creativity',
                    text: 'Designing quality web experiences, prioritizing the user throughout our projects and contributing to the growth of our customers is our core objective.'
                },
                indiv: {
                    title: 'Unique',
                    text: 'We use an individual approach to each client and we never offer you a set of standard decisions for your business.'
                },
                react: {
                    title: 'Reactivity',
                    text: 'Olina Lab always responds quickly and efficiently to all your requests, because each customer is important to us.'
                }                
            }
        },
    }
}