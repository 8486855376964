export default {
    fr: {
        about: {
            title: 'Ceci est une page à propos'
        },
    },
    en: {
        about: {
            title: 'This an about page'
        },
    }
}