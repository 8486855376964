export default {
    fr: {
        form: {
            name: {
                first: 'Votre Prénom',
                last: 'Votre Nom'
            },
            email: 'Votre adresse e-mail',
            phone: 'Votre numéro de téléphone',
            date: 'Veuillez sélectionner une date',
            required: 'Ce champs est requis.',
            'invalid-email': 'Vous devez saisir une adresse e-mail valide.',
            'invalid-phone': 'Vous devez saisir un numéro de téléphone valide.'
        }
    },
    en: {
        form: {
            name: {
                first: 'Your first name',
                last: 'Your last name'
            },
            email: 'Your email address',
            phone: 'Your phone number',
            date: 'Please select a date',
            required: 'This field is required.',
            'invalid-email': 'You must write a valid email address.',
            'invalid-phone': 'You must write a valid phone number.'
        }
    }
}