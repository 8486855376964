<template>
    <!-- Footer -->
    <footer class="page-footer font-small unique-color-dark">

        <!-- Social links -->
        <div class="social-links">
            <div class="container">
                <div class="row py-4 d-flex align-items-center">
                    <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                        <h6 class="mb-0">{{ $t('footer.social-networks') }}</h6>
                    </div>
                    <div class="col-md-6 col-lg-7 text-center text-md-right">
                        <a v-for="item in social" :key="item.label" :href="item.to" class="social-icon mx-4">
                            <span :class="item.icon"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Social links -->

        <!-- Footer Links -->
        <div class="footer-links text-center text-md-left pt-5">
            <div class="container">
                <div class="row mt-3">
                    <div class="col-md-6 col-lg-4 col-xl-3 mx-auto mb-4">
                        <h6 class="text-uppercase font-weight-bold">Olina Lab</h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                        <p class="text-justify">
                            {{ $t('footer.desc') }}
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 mx-auto mb-4">
                        <h6 class="text-uppercase font-weight-bold">{{ $t('footer.projects') }}</h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                        <p v-for="item in projects" :key="item.label">
                            <router-link :to="item.to">{{ item.label }}</router-link>
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-2 col-xl-2 mx-auto mb-4">
                        <h6 class="text-uppercase font-weight-bold">{{ $t('footer.links.title') }}</h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                        <p v-for="item in usefulLinks" :key="item.label">
                            <router-link :to="item.to">{{ item.label }}</router-link>
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        <h6 class="text-uppercase font-weight-bold">Contact</h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                        <p v-for="item in contact" :key='item.label'>
                            <a :href="item.to"><i :class="item.icon"></i> {{ item.text }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Links -->

        <!-- Copyright -->
        <div class="footer-copyright text-center py-3">
            v{{ version }} &copy; {{ new Date().getFullYear() }} Olina Lab {{ $t('footer.copyright')}}
        </div>
        <!-- Copyright -->

    </footer>
    <!-- Footer -->
</template>


<script>
    import { mapGetters } from 'vuex'

    import { ourProjects } from '@/data/projects.js'

    export default {
        name: 'Footer',

        computed: {
            ...mapGetters({
                getRoute: 'ui/getRoute'
            }),
            version() {
                return process.env.VUE_APP_VERSION
            },
            projects() {
                return [
                    {
                        label: ourProjects.projects[0].title,
                        to: this.getRoute('SingleProject', ourProjects.projects[0].slug)
                    },
                    {
                        label: ourProjects.projects[1].title,
                        to: this.getRoute('SingleProject', ourProjects.projects[1].slug)
                    },
                    {
                        label: ourProjects.projects[2].title,
                        to: this.getRoute('SingleProject', ourProjects.projects[2].slug)
                    },
                    {
                        label: ourProjects.projects[3].title,
                        to: this.getRoute('SingleProject', ourProjects.projects[3].slug)
                    }
                ]
            },
            usefulLinks() {
                return [
                    {
                        label: this.$t('footer.links.start'),
                        to: this.getRoute('Start')
                    },
                    {
                        label: 'Contact',
                        to: this.getRoute('Contact')
                    },
                    {
                        label: this.$t('footer.links.mentions'),
                        to: this.getRoute('MentionsLegales')
                    },
                    {
                        label: this.$t('footer.links.data'),
                        to: this.getRoute('DonneesPersonnelles')
                    }
                ]
            },
            social() {
                return [
                    {
                        label: 'facebook',
                        icon: 'icon mdi mdi-facebook white-text',
                        to: process.env.VUE_APP_FACEBOOK_URL
                    },
                    {
                        label: 'instagram',
                        icon: 'icon mdi mdi-instagram white-text',
                        to: process.env.VUE_APP_INSTAGRAM_URL
                    },
                    {
                        label: 'linkedin',
                        icon: 'icon mdi mdi-linkedin white-text',
                        to: process.env.VUE_APP_LINKEDIN_URL
                    }
                ]
            },
            contact() {
                return [
                    {
                        label: 'adresse',
                        text: '14000 Caen, Normandie',
                        icon: 'fas fa-map-pin mr-3',
                        to: ''
                    },
                    {
                        label: 'mail',
                        text: 'hello@olinalab.fr',
                        icon: 'fas fa-envelope mr-3',
                        to: 'mailto:hello@olinalab.fr'
                    },
                    {
                        label: 'phone',
                        text: '+33 6 81 94 61 37',
                        icon: 'fas fa-mobile-alt mr-3',
                        to: 'tel:0681946137'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
.page-footer {
    .social-links {
        background-color: $primary;

        .social-icon {
            span::before {
                font-size: 30px;
            }
        }
    }

    .footer-links {
        background-color: #1c2331;

        p {
            font-weight: normal;
        }

        a {
            transition: 250ms ease-in-out;

            &:hover {
                color: $primary
            }
        }
    }

    .footer-copyright {
        background-color: $black;
        font-weight: normal;
        font-size: .7rem;
    }

    .deep-purple {
        background-color: $primary;
    }
}
</style>