export const ourProjects = {
  projects: [
    {
      id: 1,
      slug: 'commutr',
      type: 'sol',
      type_long: 'Application web',
      title: 'Commutr',
      client: 'Side project',
      desc: 'Une application web pour accès à des statistiques sur vos trajets du quotidien et découvrir votre impact sur la planète.',
      desc_long:
        "Commutr est une application web qui, utilisée avec Strava, donne accès à des statistiques sur vos trajets du quotidien et permet à l'utilisateur de découvrir son impact sur la planète. Cette application regroupe un large ensemble de données (comme le nombre de trajets, les kilomètres parcourus, le CO2 non emis mais aussi l'argent économisé) toutes obtenus grâce à l'API de Strava.",
      picture: require('../assets/img/projects/commute-pulse/main.jpg'),
      link: 'https://commute.olinalab.fr',
      technos: ['vuejs', 'strava'],
      content: [
        {
          image: require('../assets/img/projects/commute-pulse/sc-1.jpg'),
          imageAlt: 'Commutr visualisation',
          title: 'Visualisation',
          subtitle:
            "Les données clefs sont affichées de façon efficace pour un compréhension en un coup d'oeil.",
          description:
            "La plus value de Commutr étant ses données, un gros travail à été fourni pour afficher ces dernières de façon intuitive et efficace. L'utilisateur retrouve donc différents type de visualisation, entre les graphiques et le comparatif avec d'autres modes de transports.",
        },
        {
          image: require('../assets/img/projects/commute-pulse/sc-2.jpg'),
          imageAlt: 'Commutr réseau social',
          title: 'Réseau social',
          subtitle: 'Couplé à Strava, Commutr devient un vrai lieu de partage.',
          description:
            "Un système de badges pour la gamification de vos trajets du quotidien, voilà ce que propose Commutr. Vous pouvez alors vous challengez avec vos amis, vos collègues ou même votre famille. Un véritable soin à été apporté à cet aspect de l'application.",
        },
      ],
    },
    {
      id: 2,
      slug: 'blanchard',
      type: 'site',
      type_long: 'Site web vitrine',
      title: 'Blanchard',
      client: 'Blanchard',
      desc: "Un site web pour présenter l'activité de cette entreprise spécialisée dans la mécanique générale.",
      desc_long:
        'Depuis sa création en 1981, l’entreprise Blanchard, située à Mortagne-au-Perche, est présente sur le marché de la mécanique générale et de précision. Dotée de machines à commande numérique de dernière génération et d’une équipe expérimentée, Blanchard met à disposition ses compétences pour la réalisation de pièces unitaires à la moyenne série.',
      picture: require('../assets/img/projects/blanchard/main.webp'),
      link: 'http://www.blanchard-meca.fr',
      technos: ['vuejs', 'sass', 'bootstrap'],
      content: [
        {
          image: require('../assets/img/projects/blanchard/sc-1.webp'),
          imageAlt: 'Blanchard modernize',
          title: "Moderniser l'image",
          subtitle:
            "Le premier objectif était de moderniser l’image de Blanchard, au travers d'un tout nouveau site.",
          description:
            "L'entreprise Blanchard a toujours su évoluer avec son temps, en utilisant les dernières technologies pour sa production. Le nouveau site suit donc cette direction en axant la communication sur les équipements à la peinte que possède Blanchard.",
        },
        {
          image: require('../assets/img/projects/blanchard/sc-2.webp'),
          imageAlt: 'Blanchard desktop',
          title: 'Intuitif et responsive',
          subtitle:
            'Blanchard est un acteur dans de nombreux secteurs, un site accessible pour tous est un enjeu majeur.',
          description:
            "Le fonctionnement d’un site intuitif et responsive a été un élément primordial pour s’adresser à l’ensemble du marché de Blanchard. C'est pourquoi un soin tout particulier a été apporté au desgin et la navigation sur le site, permettant à l'utilisateur d'évoluer de façon simple.",
        },
      ],
    },
    {
      id: 3,
      slug: 'aeroport-de-caen',
      type: 'sol',
      type_long: 'Application web',
      title: 'Aéroport de Caen',
      client: 'Aéroport de Caen-Carpiquet',
      desc: 'Une application web permettant de gérer les stocks de carburant ainsi que les engins sur la plateforme.',
      desc_long:
        "L'aéroport de Caen-Carpiquet est une plateforme régionale qui connaît une croissance fulgurante depuis quelques années. C'est dans ce contexte qu'ils ont mandaté Olina Lab pour créer un logiciel sur-mesure pour gérer au mieux leur stock de carburant et assurer un suivi de qualité.",
      picture: require('../assets/img/projects/cfr/main.png'),
      link: '',
      technos: ['symfony', 'bootstrap', 'mysql'],
      content: [
        {
          image: require('../assets/img/projects/cfr/sc-1.png'),
          imageAlt: 'alt',
          title: 'Sur-mesure',
          subtitle:
            "L'application a été développée en étroite collaboration avec le client, pour répondre efficacement à ses besoins.",
          description:
            "Dans le but de répondre à toutes les attentes fonctionnelles du client, Olina Lab a effectué une étude en profondeur afin d'atteindre les objectifs de l'aéroport et de poser des bases saines et solides à ce projet.",
        },
        {
          image: require('../assets/img/projects/cfr/sc-2.png'),
          imageAlt: 'alt',
          title: 'Gain de temps',
          subtitle:
            "L'application permet une gestion et une communication efficace, donc un gain de temps pour les équipes de l'aéroport.",
          description:
            "Un tel outil est une réelle plus-value qui aide l'aéroport a continuer de se développer sur le plan national. Les différents équipes intervenant sur l'application gère maintenant les stocks de carburant et les engins de la plateforme de manière efficace, transparente et lisible.",
        },
      ],
    },
    {
      id: 4,
      slug: 'lfdp-commande',
      type: 'sol',
      type_long: 'Application web',
      title: 'LFDP Commande',
      client: 'Les Friandises du Perche',
      desc: 'Une interface web permettant aux dépôts de passer commande auprès du site de production de cette boulangerie percheronne.',
      desc_long:
        "Les Friandises du Perche sont trois boulangeries situées dans l'Orne. Olina Lab a été mandaté pour créer une solution web permettant aux boutiques ne produisant pas de passer des commandes de différents produits auprès du site du production des boulangeries : pains, pâtisseries, viennoiseries, etc. Ce projet a vu le jour en mars 2019.",
      picture: require('../assets/img/projects/lfdp-commande/main.png'),
      link: '',
      technos: ['vuejs', 'php', 'mysql'],
      content: [
        {
          image: require('../assets/img/projects/lfdp-commande/sc-1.jpg'),
          imageAlt: 'Les Friandises du Perche API REST',
          title: 'Une API REST',
          subtitle:
            'Olina Lab a créé une petite API REST en PHP afin de rendre la base de données de notre client accessible et utilisable par cet outil.',
          description:
            "L'utilisation de la base de données des produits proposés par le client était une nécessité. Olina Lab a donc créé une simple API REST afin de rendre cette liste de produit plus simple à manipuler pour son client.",
        },
        {
          image: require('../assets/img/projects/lfdp-commande/sc-2.png'),
          imageAlt: 'Les Friandises du Perche tablette',
          title: 'Design orienté mobile',
          subtitle:
            "L'outil de commande devant être utilisé sur des tablettes, Olina Lab a créé un design simple et orienté mobile.",
          description:
            "Le design de ce projet a été pensé pour coller à tous les besoins du client, notamment celui de l'utilisation quasi systématique de tablettes. Le design devait également rester simple et sobre pour ne pas empiéter sur les fonctionnalités.",
        },
        {
          image: require('../assets/img/projects/lfdp-commande/sc-3.jpg'),
          imageAlt: 'Les Friandises du Perche UX',
          title: 'Recherche UX',
          subtitle:
            "Afin de rendre l'outil le plus intuitif possible, Olina Lab a pensé dans les moindres détails l'expérience utilisateur.",
          description:
            "Olina Lab a créé un formulaire en plusieurs étapes, simple à suivre et à compléter pour l'utilisateur. Des recherches dans la liste des produits, avec auto-complétion, permettent une recherche rapide et donc un passage de commande efficace.",
        },
      ],
    },
    {
      id: 5,
      slug: 'normandie-merule',
      type: 'site',
      type_long: 'Landing page',
      title: 'Normandie Mérule',
      client: 'Normandie Mérule',
      desc: "Un site web pour lancer l'activité de cette entreprise de traitement de l'habitat.",
      desc_long:
        "Normandie Mérule est une entreprise qui intervient dans le traitement des champignons dont la mérule, le traitement du bois, de l'humidité ou encore le démoussage. Afin de promouvoir leur activité, Normandie Mérule avait besoin d'un support intuitif, sobre et portable. Ce projet a été lancé en mars 2022.",
      picture: require('../assets/img/projects/normandie-merule/main.jpg'),
      link: 'https://normandie-merule.fr',
      technos: ['vuejs', 'sass', 'bootstrap'],
      content: [
        {
          image: require('../assets/img/projects/normandie-merule/sc-1.jpg'),
          imageAlt: 'Normandie Mérule browser',
          title: 'Sobre & efficace',
          subtitle:
            'Un design sobre et efficace qui collait parfaitement aux valeurs et aux activités de Normandie Mérule.',
          description:
            "À l'image de ces réalisations, Normandie Mérule avait besoin de présenter son activité de façon efficace. C'est animé par ces valeurs que le design a été pensé main dans la main avec Normandie Mérule et le résultat est à la hauteur de leurs espérances.",
        },
        {
          image: require('../assets/img/projects/normandie-merule/sc-2.jpg'),
          imageAlt: 'Normandie Mérule SEO',
          title: 'SEO',
          subtitle:
            "Normandie Mérule a besoin de visibilité et donc d'un bon référencement sur les moteurs de recherche.",
          description:
            "Dans un soucis de fournir à notre client le meilleur résultat possible, Olina Lab a apporté un soin tout particulier à l'optimisation du référencement naturel (SEO) afin que le site remonte le plus haut possible sur certains mot-clés bien définis.",
        },
      ],
    },
    {
      id: 6,
      slug: 'emery-paysages',
      type: 'site',
      type_long: 'Site web vitrine',
      title: 'Emery Paysages',
      client: 'Emery Paysages',
      desc: "Un site web vitrine pour une entreprise spécialisée dans l'aménagement du paysage.",
      desc_long:
        "Emery Paysages est un entreprise spécialisée dans l'aménagement du paysage : pose de clôtures et portails, entretien d'espaces verts, allées et bordures, etc. Après plus que 40 ans d'exisentence, Emery Paysages mandate Olina Lab en 2020 pour une refonte totale de leur site web.",
      picture: require('../assets/img/projects/emery-paysages/main.jpg'),
      link: 'http://www.emery-paysages.fr',
      technos: ['vuejs', 'sass', 'bootstrap'],
      content: [
        {
          image: require('../assets/img/projects/emery-paysages/sc-1.png'),
          imageAlt: 'alt',
          title: 'Intuitif & efficace',
          subtitle:
            "L'objectif était de créer un site simple mettant en avant les compétences clefs d'Emery Paysages.",
          description:
            "L'activité d'Emery Paysages étant très diversifiée, il a fallu fournir un vrai travail en amont afin de découper leur travail en cinq catégories clefs et parlantes. Un design simple permet ici une navigation intuitive au sein des catégories.",
        },
        {
          image: require('../assets/img/projects/emery-paysages/sc-2.png'),
          imageAlt: 'alt',
          title: 'À leur image',
          subtitle:
            'Emery Paysages est réputée grâce à son travail précis et de qualité, il leur fallait donc un site à leur image.',
          description:
            "Un site sobre et rapide devait donc voir le jour, ce qu'Olina Lab s'est efforcé à faire du début à la fin. Le résultat est à la hauteur des attentes, la sobriété et la qualité sont à l'image d'une entreprise du rang d'Emery Paysages.",
        },
      ],
    },
    {
      id: 7,
      slug: 'raid-potes-eco',
      type: 'site',
      type_long: 'Landing page',
      title: 'Raid Potes &co',
      client: 'Raid Potes &co',
      desc: "Une landing page pour Raid Potes &co, une association de trois amis qui participe à l'Europ'Raid 2021.",
      desc_long:
        "Raid Potes &co est une association de trois amis qui participe à l'Europ'Raid 2021. Ils avaient besoin d'un site web simple afin de présenter les membres de l'équipage ainsi que leur projet sportif et humanitaire. Ce projet a été lancé en décembre 2019.",
      picture: require('../assets/img/projects/rpc/main.jpg'),
      link: '',
      technos: ['vuejs', 'sass', 'bootstrap'],
      content: [
        {
          image: require('../assets/img/projects/rpc/sc-1.png'),
          imageAlt: 'Raid Pote Eco browser',
          title: 'Une landing page',
          subtitle:
            "Le format court et efficace d'une landing page collait le mieux aux besoins de Raid Potes &co.",
          description:
            "Cela permet à Raid Potes &co de disposer d'un site simple, qui tient en une page et qui invite l'utilisateur à suivre leur aventure sur les réseaux sociaux et à les soutenir via leur cagnotte en ligne.",
        },
        {
          image: require('../assets/img/projects/rpc/sc-2.png'),
          imageAlt: 'Raid Pote Eco iphone',
          title: 'Un design sobre',
          subtitle:
            "Olina Lab a choisi un design sobre et efficace permettant à l'utilisateur d'apprécier l'expérience.",
          description:
            'Dans un soucis de fournir à notre client un beau support, Olina Lab a apporté un soin tout particulier à ce design, en utilisant des fonctionnalités modernes. Ce qui rends le site aussi beau que rapide.',
        },
      ],
    },
  ],
};

export default [ourProjects];
