export default {
    fr: {
        start: {
            title: "Un projet ? <br> Lancez-vous !",
            vous: "Parlons de vous",
            projet: "Parlons de votre projet",
            budget: 'Votre budget',
            date: 'Votre date de lancement',
            desc: "Décrivez-nous votre projet",
            envoyer: "C'est parti !"
        },
    },
    en: {
        start: {
            title: "A project? <br> Let's get started!",
            vous: "Let's talk about you",
            projet: "Let's talk about your project",
            budget: 'Your budget',
            date: 'Your lauching date',
            desc: "Describe your project",
            envoyer: "Let's go!"
        },
    }
}