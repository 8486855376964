export default {
    namespaced: true,

    state: {
        locale: {
            locale: null,
            loaded: false,
        },
    },

    getters: {
        getRoute: state => (route, projectSlug) => {
            return {
                name: `${route}-${state.locale.locale}`,
                ...(projectSlug && { params: { projectSlug } })

            }
        },
        locale: state => state.locale.locale,
        localeLoaded: state => state.locale.loaded,
        oppositeLocale: state => (state.locale.locale === 'fr' ? 'en' : 'fr'),
    },

    actions: {
        setLocale({ commit, getters }, locale) {
            commit('SET_LOCALE', locale)
            if (!getters.localeLoaded) commit('SET_LOCALE_LOADED')
        },
    },

    mutations: {
        ['SET_LOCALE'](state, locale) {
            state.locale.locale = locale
        },

        ['SET_LOCALE_LOADED'](state) {
            state.locale.loaded = true
        },

    },
}
