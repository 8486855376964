export default {
    fr: {
        nav: {
            home: 'Accueil',
            about: 'À propos',
            projects: 'Projets',
            start: 'Devis gratuit'
        },
    },
    en: {
        nav: {
            home: 'Home',
            about: 'About',
            projects: 'Projects',
            start: 'Free quote'
        },
    }
}