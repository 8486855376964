import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '@/main.js'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
  /*
   * Home page FR et EN
   */
  {
    path: '/',
    name: 'Home-fr',
    component: function () {
      return import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en',
    name: 'Home-en',
    component: function () {
      return import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    meta: { lang: 'en' }
  },

  /*
   * About page FR et EN
   */
  {
    path: '/about',
    name: 'About-fr',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    meta: { lang: 'fr' },
  },
  {
    path: '/en/about',
    name: 'About-en',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    meta: { lang: 'en' },
  },

  /*
   * Projects page FR et EN
   */
  {
    path: '/projets',
    name: 'Projects-fr',
    component: function () {
      return import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
    },
    meta: { lang: 'fr' },
  },
  {
    path: '/en/projects',
    name: 'Projects-en',
    component: function () {
      return import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
    },
    meta: { lang: 'en' },
  },

  /*
   * Single Project page FR et EN
   */
  {
    path: '/projets/:projectSlug',
    name: 'SingleProject-fr',
    component: function () {
      return import(/* webpackChunkName: "single-project" */ '../views/SingleProject.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en/projects/:projectSlug',
    name: 'SingleProject-en',
    component: function () {
      return import(/* webpackChunkName: "single-project" */ '../views/SingleProject.vue')
    },
    meta: { lang: 'en' }
  },

  /*
   * Start page FR et EN
   */
  {
    path: '/debuter',
    name: 'Start-fr',
    component: function () {
      return import(/* webpackChunkName: "start" */ '../views/Start.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en/start',
    name: 'Start-en',
    component: function () {
      return import(/* webpackChunkName: "start" */ '../views/Start.vue')
    },
    meta: { lang: 'en' }
  },

  /*
   * Contact page FR et EN
   */
  {
    path: '/contact',
    name: 'Contact-fr',
    component: function () {
      return import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en/contact',
    name: 'Contact-en',
    component: function () {
      return import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    meta: { lang: 'en' }
  },

  /*
   * MentionsLegales page FR et EN
   */
  {
    path: '/mentions-legales',
    name: 'MentionsLegales-fr',
    component: function () {
      return import(/* webpackChunkName: "mentions-legales" */ '../views/Policy/MentionsLegales.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en/mentions-legales',
    name: 'MentionsLegales-en',
    component: function () {
      return import(/* webpackChunkName: "mentions-legales" */ '../views/Policy/MentionsLegales.vue')
    },
    meta: { lang: 'en' }
  },

  /*
   * DonneesPersonnelles page FR et EN
   */
  {
    path: '/donnees-personnelles',
    name: 'DonneesPersonnelles-fr',
    component: function () {
      return import(/* webpackChunkName: "donnees-personnelles" */ '../views/Policy/DonneesPersonnelles.vue')
    },
    meta: { lang: 'fr' }
  },
  {
    path: '/en/donnees-personnelles',
    name: 'DonneesPersonnelles-en',
    component: function () {
      return import(/* webpackChunkName: "donnees-personnelles" */ '../views/Policy/DonneesPersonnelles.vue')
    },
    meta: { lang: 'en' }
  },

  { path: '*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const { lang } = to.meta
  document.documentElement.lang = lang
  i18n.locale = lang
  Store.dispatch('ui/setLocale', lang)

  next()
})

export default router
