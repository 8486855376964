<template>
  <div>
    <Navigation />
    <transition name="fade" mode="out-in">
      <router-view class="page-content"></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  computed: {
    ...mapGetters({
      locale: 'ui/locale',
      localeLoaded: 'ui/localeLoaded',
      oppositeLocale: 'ui/oppositeLocale'
    }),

    oppositeRouteName() {
      let name = null
      if (this.$route.name) {
        name = this.$route.name.split('-')[0] + '-' + this.oppositeLocale
      }
      return name ? { name } : null
    },
  }
};
</script>

<style src="./assets/scss/main.scss" lang="scss"></style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>