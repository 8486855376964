import nav from './translations/nav.js'
import home from './translations/home.js'
import about from './translations/about.js'
import footer from './translations/footer.js'
import form from './translations/form.js'
import start from './translations/start-project.js'

export default {
    fr: { ...nav.fr, ...home.fr, ...about.fr, ...footer.fr, ...form.fr, ...start.fr },
    en: { ...nav.en, ...home.en, ...about.en, ...footer.en, ...form.en, ...start.en },
}